.add-animation-to-navigation-menu {
  scroll-behavior: smooth;
  animation: navigation-component 1 0.5s normal;
}

@keyframes navigation-component {
  from {
    height: 0%;
  }
  to {
    height: 208px;
  }
}
