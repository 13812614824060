@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.add-animation-to-main-app {
  scroll-behavior: smooth;
  animation: main-component-animation 1 2s normal;
}

@keyframes main-component-animation {
  from {
    margin: 100%;
  }
  to {
    margin: 0%;
    top: 0%;
  }
}
