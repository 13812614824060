@import url("https://fonts.googleapis.com/css2?family=Aboreto&family=Fira+Code&family=Piazzolla:wght@900&family=Poppins:wght@300&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: default;
  scroll-behavior: smooth !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
  width: 4px;
  background: #93c5fd;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #93c5fd;
  border-radius: 30%;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@layer components {
  .skills-box-css {
    @apply md:mx-2 my-4 min-w-[200px] w-full flex flex-col justify-center p-2 font-bold text-xl text-white sm:w-fit border hover:bg-gray-800 rounded-md;
  }

  .apply-css-to-skills-inside-box {
    @apply w-full text-white p-2 space-y-2;
  }

  .add_zoom {
    transition: 0.2s;
  }
  .add_zoom:hover {
    transform: scale(1.1);
  }

  .add_table_border {
    @apply border-[2px] border-black;
  }
}
